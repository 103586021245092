


[type="checkbox"], label {
	display: none;
}
nav {
    &:after {
        content:'';
        display: block;
        clear:both;
        visibility: hidden;
    }
}
@media screen and (max-width: $mobile-menu-bp) {
	nav ul {
		display: none;
	}

	nav label {
  		display: block;
  		background: #00a0e3;
		width: 40px;
  		height: 40px;
  		cursor: pointer;
		position: fixed;
  		top: 0px;
	}
  
	nav label:after{
		content:'';
		display: block;
		width: 30px;
		height: 5px;
		background: #fff;
		margin: 7px 5px;
		box-shadow: 0px 10px 0px #fff, 0px 20px 0px #fff
	}

	nav [type="checkbox"]:checked ~ ul {
  		display: block;
  		z-index: 9999;
		position: fixed !important;
		top: 40px;
		background-color: rgba(255, 255, 255, 0.85);
		border-left: 2px solid #00a0e3;
		border-bottom: 2px solid #00a0e3;
		padding: 15px 15px 0px 20px;
	}

	nav ul li {
		display: block;
  		float: none;
  		width: 100%;
  		text-align: left;
	}
		
	nav > ul > li {
  		margin-left: 0px !important;
	}

	nav > ul li ul li {
  		display: block;
		float: none;
	}
  
	nav > ul li ul {
		display: block;
  		position: relative;
  		width: 100%;
		z-index: 9999;
		float: none;
	}
	
	nav h1 {
		font-size: 26px;
	}
	
}
