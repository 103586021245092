html {
    overflow-x: hidden;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #242d58;
    background: #fff;
    opacity: 0;
    overflow-x: hidden;
}

*,
h1 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    border: none;
    color: #242d58;
    outline: 0;
    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }
}

input {
    font-family: 'Open Sans', sans-serif;
    outline: 0;
}

ul {
    list-style-type: none;
}

p {
    margin: 20px 0;
}

.uppercase {
    text-transform: uppercase;
}

.clear {
    clear: both;
}

.wrapper,
.mwrapper {
    margin: 0 auto;
}

.visible {
    opacity: 1;
}

section {
    padding: 75px 0 115px;
    div>h1,
    h1,
    >div>h5 {
        text-transform: uppercase;
        font-size: 84px;
        line-height: 84px;
        color: #00a0e3;
        margin-bottom: 84px;
        font-weight: 800;
        margin-top: 0;
    }
    div>h1 span.dark,
    h1 span.dark,
    >div>h5 span.dark {
        color: #242d58;
    }
}

.divider {
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 80px;
    padding-bottom: 70px;
}

.nomargin {
    margin: 0;
    padding: 0;
}

.widget_breadcrumb_navxt {
    color: #242d58;
    font-weight: 700;
    span {
        font-weight: 400;
        &.separator {
            color: #272f5a;
            font-weight: 700;
            margin: 0 10px;
        }
        a {
            color: #858793;
            &.current-item {
                color: #00a0e3;
            }
        }
    }
}

header {
    position: relative;
    &.front-page {
        height: 699px;
    }
    div {
        &.top {
            &:after {
                content: '';
                clear: both;
                visibility: hidden;
                display: block;
            }
            position: relative;
            z-index: 2;
            padding-top: 43px;
            div.logo {
                float: left;
            }
            ul {
                &.menu {
                    float: left;
                    li {
                        display: inline-block;
                        font-size: 13px;
                        text-transform: uppercase;
                        font-weight: 700;
                        position: relative;
                        a {
                            text-shadow: -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff, 1px 1px 1px #fff;
                        }
                        &:nth-child(1n+2) {
                            margin-left: 40px;
                        }
                        &:hover::before,
                        &.current-menu-item::before,
                        &.current-menu-ancestor::before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            border-bottom: 3px solid #00a0e3;
                            bottom: -10px;
                        }
                    }
                }
                &.sub-menu {
                    padding-left: 20px;
                    li:nth-child(n) {
                        display: block;
                        margin: 15px 0 5px !important;
                        &:before {
                            display: none !important;
                        }
                    }
                }
                &.langswitch {
                    &.mobile-lang {
                        display: none !important;
                        @media(max-width: $mobile-menu-bp) {
                            display:block !important;
                        }
                    }
                    &.desktop-lang {
                        margin-top: 40px;
                        display: block !important;
                        @media(max-width: $mobile-menu-bp) {
                            display:none !important;
                        }
                    }
                    user-select: none;
                    z-index: 9999;
                    float: right;
                    position: relative;
                    background: #fff;
                    border: 1px solid #d6d6d6;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    cursor: pointer;
                    text-transform: uppercase;
                    li {
                        display: none;
                        font-size: 13px;
                        user-select: none;
                        text-transform: uppercase;
                        font-weight: 700;
                        &.current-lang {
                            display: block;
                            user-select: none;
                            position: relative;
                            top: 50%;
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                            padding-right: 10px;
                        }
                        &:not(.current-lang) {
                            position: relative;
                            top: 75%;
                            padding: 16px 0 0;
                            background: #fff;
                            border: 1px solid #d6d6d6;
                            border-radius: 180px;
                            width: 50px;
                            height: 50px;
                        }
                    }
                    &.expanded li:not(.current-lang) {
                        display: block;
                    }
                    &::after {
                        content: url('img/gfx_lang_toggle.png');
                        position: absolute;
                        top: 11px;
                        right: 10px;
                    }
                    &.expanded::after {
                        top: 17px;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                    }
                }
            }
        }
        &.slider {
            top: 0;
            width: 100%;
            overflow: hidden;
            position: absolute;
            height: 699px;
            border-bottom: 2px solid #40B8EA;
        }
        &.slider-small {
            height: 223px;
            position: inline;
            margin-top: 159px;
            margin-bottom: 200px;
            border-bottom: none;
        }
        &.slider {
            div.slides {
                height: 565px;
                div.slide {
                    height: 565px;
                    div.wrapper {
                        position: relative;
                        padding-top: 180px;
                    }
                    h3 {
                        font-size: 84px;
                        line-height: 70px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                    span.gray,
                    grey {
                        color: #d6d6d6;
                    }
                    span.lightblue,
                    blue {
                        color: #00a0e3;
                    }
                    span.darkblue {
                        color: #242d58;
                    }
                    img.slide-bg {
                        margin: 0 auto;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                    span.desc {
                        color: #606060;
                        display: block;
                        margin-top: 40px;
                        line-height: 26px;
                        font-size: 16px;
                        text-shadow: -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff, 1px 1px 1px #fff;
                    }
                }
            }
            ul.toggles {
                position: relative;
                padding-left: 150px;
                li {
                    float: left;
                    width: 12px;
                    height: 12px;
                    background: transparent;
                    border: 1px solid #00a0e3;
                    border-radius: 50px;
                    cursor: pointer;
                    &.active {
                        background: #242d58;
                        border: 1px solid #242d58;
                    }
                    &:nth-child(1n+2) {
                        margin-left: 6px;
                    }
                }
            }
        }
        &.scrollnext {
            position: absolute;
            bottom: 22px;
            background: transparent;
            border: 1px solid #d6d6d6;
            border-radius: 180px;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            &::after {
                content: url('img/gfx_top_scrollnext.png');
                position: relative;
                top: 7px;
            }
        }
        &.line {
            border-bottom: 2px solid #40b8ea;
            width: 100%;
            position: relative;
            margin-top: 20px;
            margin-bottom: 165px;
            top: 227px;
        }
        &.slider-small {
            h3 {
                font-size: 30pt !important;
                line-height: 150% !important;
            }
            .wrapper {
                padding-top: 50px !important;
                padding-left: 8% !important;
            }
            .toggles {
                padding: 35px 0 0 !important;
            }
            .slides {
                height: 50px !important;
            }
        }
    }
}

section {
    &.our-services {
        opacity: 0;
        ul {
            margin-right: -80px;
            margin-bottom: -65px;
            li {
                display: inline-block;
                vertical-align: top;
                position: relative;
                width: 340px;
                height: 280px;
                margin: 0 77px 65px 0;
                div.thumb {
                    position: relative;
                    height: 185px;
                    text-align: center;
                    overflow: hidden;
                    transition: opacity 0.25s ease;
                    &:hover {
                        opacity: 0.8;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        transition: all 1.5s;
                        -webkit-transition: all 1.5s;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translateY(-50%) translateX(-50%);
                        -ms-transform: translateY(-50%) translateX(-50%);
                        transform: translateY(-50%) translateX(-50%);
                        z-index: -1;
                    }
                    span {
                        &.title {
                            position: relative;
                            display: block;
                            text-transform: uppercase;
                            color: #fff;
                            padding: 0 60px;
                            font-size: 18px;
                            line-height: 22px;
                            font-weight: 800;
                            top: 50%;
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                            z-index: 320;
                            transition: opacity .5s;
                            -webkit-transition: opacity .5s;
                            opacity: 1;
                        }
                        &.desc {
                            display: block;
                            padding: 30px 15px;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 22px;
                            font-weight: 300;
                            color: #fff;
                            position: absolute;
                            text-align: left;
                            z-index: 3;
                            width: calc(100% - 30px);
                            vertical-align: middle;
                            top: 50%;
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                            left: 0;
                            letter-spacing: .7px;
                            opacity: 0;
                            transition: opacity .5s;
                            -webkit-transition: opacity 0.5s;
                        }
                    }
                    &::before {
                        content: ' ';
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 160, 227, 0.8);
                        z-index: 2;
                        opacity: 0;
                        transition: opacity .5s;
                        -webkit-transition: opacity 0.5s;
                    }
                }
                &.active {
                    div.thumb {
                        span.desc,
                        &::before {
                            opacity: 1;
                        }
                        span.title {
                            opacity: 0;
                        }
                    }
                    >a {
                        background: #00a0e3;
                        color: #fff;
                    }
                }
                >a {
                    position: absolute;
                    cursor: pointer;
                    bottom: 0;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    color: #00a0e3;
                    font-size: 13px;
                    font-weight: 800;
                    text-transform: uppercase;
                    display: block;
                    padding: 15px 17px;
                    border: 1px solid #00a0e3;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    transition: background-color 0.25s ease;
                }
            }
        }
    }
    &.our-product {
        padding: 50px 0 90px;
        opacity: 0;
        div.product {
            width: 402px;
            padding-top: 70px;
            position: absolute;
            left: calc(50% - 201px);
            z-index: 1;
            img {
                margin-top: -20px;
                position: absolute;
                display: none;
            }
            #pr-img-main {
                display: inline;
            }
            img:hover {}
        }
        a.showall {
            position: relative;
            color: #fff;
            font-size: 13px;
            font-weight: 800;
            text-transform: uppercase;
            padding: 15px 22px;
            background: #002d58;
            display: inline-block;
            margin-top: 30px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            transition: background 0.25s ease;
            &:hover {
                background-color: #00a0e3;
            }
        }
        ul {
            &.left {
                width: 300px;
                float: left;
                margin-bottom: -35px;
                position: relative;
                z-index: 2;
            }
            &.right {
                width: 300px;
                float: right;
                margin-bottom: -35px;
                position: relative;
                z-index: 2;
            }
            li {
                height: 155px;
                cursor: pointer;
                transition: opacity 0.25s ease;
                &:hover {
                    opacity: 0.8;
                }
                h3 {
                    font-weight: 300;
                    font-size: 30px;
                    line-height: 32px;
                    color: #00a0e3;
                }
                span {
                    display: block;
                    margin-top: 15px;
                    color: #858793;
                    font-size: 13px;
                    line-height: 22px;
                    width: 300px;
                }
            }
        }
    }
    &.our-company {
        background: url(img/gfx_bg_company.jpg) top center no-repeat;
        padding: 75px 0 90px;
        opacity: 0;
        h1 {
            line-height: 70px;
        }
        div {
            &.left {
                float: left;
                width: 50%;
            }
            &.right {
                float: right;
                width: 50%;
            }
            &.left {
                ul {
                    margin-bottom: 55px;
                    li {
                        font-size: 18px;
                        line-height: 18px;
                        color: #bebebe;
                        text-transform: uppercase;
                        font-weight: 700;
                        margin-bottom: 34px;
                        padding-left: 52px;
                        position: relative;
                        cursor: pointer;
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 3px;
                            display: block;
                            width: 6px;
                            height: 6px;
                            border-radius: 30px;
                            border: 3px solid #242d58;
                        }
                        &.active {
                            color: #242d58;
                            &::before {
                                border: 3px solid #00a0e3;
                            }
                        }
                    }
                }
                a {
                    display: inline-block;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 800;
                    text-transform: uppercase;
                    padding: 15px 22px;
                    background: #00a0e3;
                }
            }
            &.right ul {
                padding: 211px 0 0 110px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: #fff;
                li {
                    display: none;
                    cursor: pointer;
                    &.active {
                        display: block;
                        position: relative;
                    }
                }
            }
        }
    }
    &.our-mission {
        background: #00a0e3;
        padding: 115px 0 90px;
        opacity: 0;
        color: #fff;
        .president-photo {
            float: right;
            width: 440px;
            text-align: right;
            display: inline-block;
            margin: 0 0 30px 50px;
            font-style: italic;
            font-size: 10pt;
            img {
                width: 100%;
            }
        }
        span {
            &.desc {
                display: block;
                font-weight: 300;
                font-size: 16px;
                line-height: 30px;
            }
            &.quota {
                display: block;
                margin-top: 80px;
                font-style: italic;
                font-weight: 600;
                font-size: 29px;
                text-align: right;
            }
            &.author {
                display: block;
                color: #242d58;
                text-align: right;
                font-style: italic;
                font-weight: 700;
                font-size: 27px;
            }
        }
        h1,
        >div>h5 {
            color: #fff;
        }
    }
    &.eco {
        background: url(img/gfx_bg_eco.gif) center center no-repeat;
        padding-bottom: 75px;
        opacity: 0;
        h1 {
            color: #fff;
            span.dark {
                color: #40694B;
            }
        }
        ul {
            margin-right: -181px;
            li {
                position: relative;
                width: 270px;
                display: inline-block;
                margin-right: 181px;
                vertical-align: top;
                div.thumb {
                    text-align: center;
                    margin-bottom: 25px;
                    height: 170px;
                    position: relative;
                    @media(min-width: 1024px) {
                        img {
                            bottom: 20px;
                            left: 0;
                            position: absolute;
                        }
                    }
                }
                h5 {
                    color: #fff;
                    font-weight: 300;
                    font-size: 30px;
                }
                span {
                    margin: 15px 0 20px;
                    display: block;
                    color: #3e6a4a;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                }
                div.description {
                    display: block;
                    color: #fff;
                    font-size: 14px;
                    line-height: 22px;
                    margin-top: 50px;
                }
            }
        }
    }
    .breadcrumbs-divider {
        padding-bottom: 16px;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 98px;
    }
    &.contacts {
        .contact-form {
            padding-top: 0;
            h3 {
                text-transform: uppercase;
                color: #00a0e3;
                font-size: 18pt;
                font-weight: 400;
                margin-bottom: 30px;
            }
            .contact-field {
                display: inline-block;
                width: 33%;
                margin-top: 20px;
                .label {
                    margin: 15px 0 20px;
                    font-weight: 700;
                    font-size: 10pt;
                }
            }
            .message-field .label {
                margin: 15px 0 20px;
                font-weight: 700;
                font-size: 10pt;
            }
            .contact-field {
                input {
                    width: 75%;
                    height: 35px;
                    padding: 3px 15px;
                    font-size: 11pt;
                    border: 1px solid #0789c7;
                    color: #00a0e3;
                    font-size: 10pt;
                }
                select {
                    width: 75%;
                    height: 35px;
                    padding: 3px 15px;
                    font-size: 11pt;
                    border: 1px solid #0789c7;
                    color: #00a0e3;
                    font-size: 10pt;
                    width: 82.5%;
                    height: 43px;
                    text-transform: uppercase;
                }
            }
            .message-field {
                margin: 40px 0 0;
                textarea {
                    width: 92%;
                    max-width: 1112px;
                    min-height: 80px;
                    border: 1px solid #0789c7;
                    color: #00a0e3;
                    padding: 10px 15px;
                    font-size: 10pt;
                }
            }
            .send {
                width: 100%;
                text-align: center;
                input {
                    padding: 14px 26px;
                    border: none;
                    color: #fff;
                    font-size: 10pt;
                    text-transform: uppercase;
                    margin-top: 40px;
                    font-weight: 700;
                    margin-right: 5%;
                    transition: background 0.25s;
                    transition: all .25s ease; outline:none;
                    background: #002d58;
                    &:hover {
                        background: #00a0e3;
                    }
                }
            }
            .wpcf7-not-valid-tip {
                position: absolute;
            }
            .wpcf7-response-output {
                border: 1px solid red;
                text-align: center;
                color: #800;
                font-weight: 700;
                padding: 10px 0;
            }
        }
        .department {
            text-transform: uppercase;
            font-size: 11pt;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .contact-box {
            color: #002d58;
            font-size: 11pt;
            line-height: 160%;
            vertical-align: top;
            .image-wrapper {
                width: 100%;
                text-align: center;
                overflow: hidden;
            }
            .name {
                color: #00a0e3;
                margin-top: 20px;
            }
            .position {
                font-weight: 700;
                font-size: 10pt;
                margin-bottom: 8px;
            }
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 500px) {
    section.contacts .contact-box {
        text-align: center;
    }
}

footer {
    opacity: 0;
    background: #FFF url(img/gfx_bg_contact.jpg) center left no-repeat;
    color: #242d58;
    &:after {
        content: '';
        clear: both;
        display: block;
        visibility: hidden;
    }
    .left {
        @media(min-width: 861px) {
            float: right;
        }
        width: calc(50% - 15px);
        padding: 97px 15px 50px 0;
    }
    .right {
        float: right;
        width: 50%;
        height: 723px;
        .map {
            width: 100%;
            height: 100%;
        }
    }
    h1 {
        font-size: 34px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    form {
        margin-bottom: 90px;
        input[type="email"] {
            float: left;
            width: 310px;
            background: transparent;
            border: 1px solid #fff;
            padding: 19px;
            font-size: 17px;
            font-weight: 300;
            color: #fff;
            &::-webkit-input-placeholder,
            &:-moz-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder {
                color: #fff;
                font-weight: 300;
                font-family: "Open Sans", sans-serif;
            }
        }
        button[type="submit"] {
            display: inline-block;
            background: #fff;
            border: 1px solid #fff;
            padding: 19px;
            font-size: 17px;
            color: #00a0e3;
            text-transform: uppercase;
            font-weight: 800;
            cursor: pointer;
        }
    }
    div.box {
        float: left;
        width: 255px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        p {
            margin: 0 0 25px;
            b {
                font-weight: 800;
            }
        }
    }
    .newsletter .message {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 14pt;
    }
}

.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00a0e3;
    padding: 15px;
    border-top: 2px solid #fff;
    color: #fff;
    z-index: 999;
    font-size: 13px;
    span {
        display: inline-block;
        width: calc(100% - 120px);
        vertical-align: middle;
    }
    a.accept {
        display: inline-block;
        padding: 9px 8px;
        background: #fff;
        color: #00a0e3;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        vertical-align: middle;
    }
}

section.career {
    ul li {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin-left: 40px;
        position: relative;
        &:before {
            content: url('img/blue-dot.png');
            margin: 0 20px 0 10px;
            position: absolute;
            left: -40px;
        }
    }
    img {
        max-width: 100%;
    }
    h1 {
        margin-bottom: 50px;
    }
    h3 {
        text-transform: uppercase;
        font-size: 12pt;
        margin: 50px 0 60px;
        &.b {
            font-weight: 800;
            text-transform: uppercase;
            font-size: 30pt;
            color: #00a0e3;
            strong {
                font-weight: 800;
                color: #242d58;
            }
        }
    }
    .bottom-applynow {
        margin-top: 35px;
        text-align: center;
    }
    p.first-line {
        font-weight: 700;
        margin-bottom: 20px;
    }
    .jobs li {
        list-style-type: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        a {
            width: 340px;
            height: 110px;
            border: 1px solid #e5e5e5;
            display: inline-block;
            margin: 3px 0 53px 0 !important;
            @media(min-width: 490px) {
                margin: 3px 66px 53px 0 !important;
            }
            &.btn-mda-555 {
                @media(min-width: 490px) {
                    margin: 3px 33px 53px 33px !important;
                }
            }
            color: #00a0e3;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 16pt;
            transition: border-color .5s;
            vertical-align: top;
            div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                vertical-align: top;
            }
            &:hover {
                border: 4px solid #00a0e3;
            }
            .subinfo {
                font-size: 10pt;
                margin-top: 7px;
                color: #242d58;
                font-weight: 400;
                text-transform: none;
            }
        }
        &.applynow {
            width: 100%;
            text-align: center;
        }
        a {
            &.applynow {
                background: #00a0e3;
                color: #fff;
                margin: 0 !important;
                border: 4px solid #fff;
                &:hover {
                    border: 4px solid #00a0e3;
                }
            }
            &.applynow-rwd {
                display: none;
            }
        }
    }
    .practice {
        margin-left: -15px;
        margin-right: -15px;
        overflow: hidden;
    }
    &.cap h3 {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 30pt;
        color: #00a0e3;
        strong {
            font-weight: 800;
            color: #242d58;
        }
    }
    .practice {
        .left,
        .right {
            padding-left: 15px;
            padding-right: 15px;
        }
        .left {
            width: calc(40% - 30px);
            float: left;
        }
        .right {
            width: calc(60% - 30px);
            float: left;
        }
    }
    ul.recruitment-stages {
        list-style-type: none;
        margin-bottom: 20px;
        &:after {
            display: table;
            clear:both;
            content: '';
        }
        li {
            margin-left: 0;
            width: 33%;
            display: block;
            float:left;
            margin-bottom: 30px;
            &::before {
                display: none;
            }
            &:nth-child(4){
                clear:left;
            }
            @media(max-width: 1024px) {
                width: 50%;
                &:nth-child(4){
                    clear:none;
                }
                &:nth-child(3),
                &:nth-child(5){
                    clear:left;
                }
            }
            @media(max-width: 860px) {
                width: 100%;
            }
        }
        div.img img {
            display: inline-block;
            vertical-align: middle;
        }
        li {
            &:nth-child(n) div.top::after {
                content: '';
                background: #fff url('img/gfx_next_req.png') center no-repeat;
                border: 1px solid #d6d6d6;
                border-radius: 180px;
                width: 24px;
                height: 24px;
                display: block;
                position: absolute;
                right: 0;
                top: 10px;
            }
            &:nth-child(3) div.top::after, &:nth-child(6) div.top::after {
                display: none;
            }
            @media(max-width: 1024px){
                &:nth-child(n) div.top::after {
                    display: none;
                }
            }
        }
        div.top>div {
            display: inline-block;
            vertical-align: middle;
            color: #00a0e3;
            position: relative;
            margin-left: 10px;
            &:not(.img)::after {
                content: url('img/gfx_lang_toggle.png');
                position: absolute;
                right: -12px;
                top: -2px;
            }
        }
        li:hover .expand {
            max-height: 200px;
        }
        .expand {
            overflow: hidden;
            max-height: 0;
            padding-left: 62px;
            font-size: 13px;
            transition: all 0.75s ease;
        }
    }
}

body.page-template-template-career-practice div.jobs.center {
    text-align: center;
}

section.news {
    .news-box {
        display: inline-block;
        margin: 0 0 50px 0;
        width: 340px;
        color: #002d58;
        font-size: 10pt;
        line-height: 175%;
        vertical-align: top;
        img {
            width: 100%;
        }
        .image-wrapper {
            position: relative;
            line-height: 0;
            transition: opacity .25s ease;
            height: 217px; // border: 1px solid #242d58;
            overflow: hidden;
            &:hover {
                opacity: 0.7;
            }
            .date {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 82px;
                height: 82px;
                background: #242d58;
                text-align: center;
                line-height: 240%;
                .day {
                    font-size: 30pt;
                    color: #fff;
                    font-weight: 700;
                    margin-top: 14px;
                }
                .month-year {
                    font-size: 10pt;
                    color: #b7bfe8;
                }
            }
        }
        h3 a {
            color: #00a0e3;
            text-transform: uppercase;
            margin: 20px 0 10px;
            font-size: 11pt;
            display: inline-block;
            transition: color 0.25s ease;
            &:hover {
                color: #31b6ee;
            }
        }
        a.readmore {
            text-decoration: underline;
            color: #b0b0b0;
            text-transform: uppercase;
            font-size: 9pt;
            margin-top: 30px;
            display: inline-block;
            transition: color 0.25s ease;
            &:hover {
                color: gray;
            }
        }
    }
    .divider {
        margin: -100px 0 50px;
    }
}

.pagination {
    text-align: right;
    color: #242d58;
    .prev {
        margin-right: 15px;
        vertical-align: middle;
    }
    .next {
        margin-left: 15px;
        vertical-align: middle;
    }
    > {
        input,
        a,
        span {
            padding: 2px 8px;
            font-size: 11pt;
            vertical-align: middle;
            margin: 0 2px;
            border: 0;
            background-color: transparent;
        }
        input {
            &.current,
            &.page-numbers:hover {
                background-color: #00a0e3;
                color: #fff;
            }
        }
        span.current,
        a.page-numbers:hover {
            background-color: #00a0e3;
            color: #fff;
        }
    }
    input {
        &.prev:hover,
        &.next:hover {
            background-color: #eee;
        }
    }
    a {
        &.prev:hover,
        &.next:hover {
            background-color: #eee;
        }
    }
    .dots {
        color: #00a0e3;
    }
    >input {
        &.arrow-next {
            &:hover {
                color: transparent;
                background: url(img/arrow_right.png) center no-repeat;
            }
            color: transparent;
            background: url(img/arrow_right.png) center no-repeat;
        }
        &.arrow-prev {
            &:hover {
                color: transparent;
                background: url(img/arrow_left.png) center no-repeat;
            }
            color: transparent;
            background: url(img/arrow_left.png) center no-repeat;
        }
    }
}

section {
    &.single {
        .date {
            font-weight: 700;
            font-size: 10pt;
        }
        font-size: 11pt;
        line-height: 180%;
        a {
            color: #00a0e3;
        }
        div.thumbnail {
            margin: -44px 0 34px;
        }
    }
    &.clients {
        .wrapper p {
            margin-bottom: 25px;
            &.bold {
                font-weight: 700;
            }
        }
        .world {
            text-align: center;
            margin: 100px 0;
            img {
                width: 100%;
                height: auto;
                max-width: 982px;
            }
        }
        .logos img {
            border: 1px solid #e5e5e5;
            margin: 0 11px 11px 0;
            border: 1px solid #fff;
            transition: all 0.25s ease;
            &:hover {
                border-color: #00a0e3;
                opacity: 0.7;
            }
        }
    }
    &.about {
        padding-bottom: 0;
        .first {
            font-weight: 700;
            margin-bottom: 50px;
        }
        .about-box {
            display: inline-block;
            margin: 0 70px 100px 0;
            width: 340px;
            color: #002d58;
            font-size: 10pt;
            line-height: 175%;
            vertical-align: top;
            img {
                max-width: 100%;
                max-height: 306px;
                transition: opacity 0.25s ease;
                &:hover {
                    opacity: 0.8;
                }
            }
            .image-wrapper {
                position: relative;
                line-height: 0;
            }
        }
    }
    &.numbers h1 span.darker,
    &.history h1 span.darker {
        color: #242d58;
    }
    &.about {
        h3 {
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 13pt;
        }
        .about-box h3 {
            color: #00a0e3;
            text-transform: uppercase;
            margin: 20px 0 10px;
            font-size: 11pt;
            display: block;
        }
    }
    &.numbers {
        h3 {
            text-transform: uppercase;
            color: 242d58;
            margin: 50px 0 30px;
            font-size: 16pt;
        }
        .size-box,
        .wolumen-box {
            display: inline-block;
            height: 170px;
            border: 1px solid #e3e3e3;
            border-right: none;
            margin: 0;
            text-align: center;
            line-height: 150%;
            vertical-align: top;
            position: relative;
        }
        .numbers-wrapper {
            margin-top: 60px;
            position: relative;
        }
        .size-box {
            width: 295px;
            &:nth-child(4) {
                border-right: 1px solid #e3e3e3;
            }
            @media(min-width: 1024px) and (max-width: 1240px) {
                &:nth-child(4) {
                    border-top: none;
                    border-right: none;
                }
            }
            @media(min-width: 861px) {
                &:nth-child(5),
                &:nth-child(6) {
                    border-top: none;
                }
            }
        }
        .wolumen-box {
            width: 393px;
        }
        .size-box:last-of-type,
        .wolumen-box:last-of-type {
            border-right: 1px solid #e3e3e3;
        }
        .number {
            font-size: 36pt;
            font-weight: 800;
            color: #00a0e3;
        }
        .unit {
            font-size: 22pt;
        }
        .description {
            font-size: 12pt;
            font-weight: 700;
        }
    }
    &.history {
        background: #00a0e3;
        margin: 0 0 60px;
        position: relative;
        h1 {
            color: #fff;
        }
        .scrollbar-outer {
            overflow: hidden !important;
            .scroll-element.scroll-y {
                height: auto!important;
            }
        }
        .calendar {
            display: inline-block;
            margin: 0 15% 100px 0;
        }
        .external-scroll_x {
            background: #242d58;
            cursor: pointer;
            display: none;
            position: relative;
            border-radius: 5px;
            margin: 0 auto;
            &.scroll-scrollx_visible {
                display: block;
                height: 6px;
                width: 500px;
            }
            div {
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
            }
            .scroll-bar {
                background: #81e0ff;
                z-index: 12;
                border-radius: 5px;
                width: 140px;
            }
        }
    }
    &.text .left-menu {
        display: inline-block;
        width: 280px;
        background: #00a0e3;
        vertical-align: top;
        padding: 10px 0;
        li a {
            padding: 6px 25px;
            display: block;
        }
        a {
            color: #fff;
            text-transform: uppercase;
            font-size: 10pt;
            font-weight: 400;
            transition: color 0.25s ease;
            &:hover {
                color: #b6dbea;
            }
        }
        .current-menu-item>a,
        .current-menu-ancestor>a {
            font-weight: 700;
        }
        li ul {
            display: none;
        }
        .current-menu-item>ul {
            display: block !important;
        }
    }
}

.left-menu .current-menu-ancestor>ul {
    display: block !important;
}

section {
    &.text {
        .left-menu .sub-menu {
            background: #0789c7;
            padding: 2px 0;
            margin: 5px 0;
            a {
                text-transform: initial;
                font-size: 9pt;
                color: #c1ebff;
                transition: color 0.25s ease;
                &:hover {
                    color: #9acee7;
                }
            }
            .sub-menu {
                padding-left: 30px;
            }
        }
        .right-content {
            display: inline-block;
            width: 850px;
            vertical-align: top;
            margin-left: 40px;
            font-size: 11pt;
            a {
                color: #00a0e3;
                transition: color 0.25s ease;
                &:hover {
                    color: #4dbdeb;
                }
            }
            h3 {
                font-size: 22pt;
                color: #00a0e3;
                text-transform: uppercase;
                font-weight: 400;
                margin: -10px 0 15px;
            }
        }
    }
    &.products {
        h3 {
            text-transform: uppercase;
            color: #00a0e3;
            font-weight: 400;
            margin: 50px 0 40px;
            font-size: 18pt;
        }
        .product-lists {
            margin-bottom: 40px;
            >a {
                display: inline-block;
                width: 183px;
                height: 160px;
                vertical-align: top;
                margin-right: 15px;
                background-color: #242d58;
                background-position: center;
                padding: 0 20px;
                transition: background-color 0.25s ease;
                &:hover,
                &.current {
                    background-color: #00a0e3;
                }
                >div {
                    position: relative;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    top: 50%;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 12pt;
                    color: #fff;
                    width: 100%;
                    font-weight: 700;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        .products-triangle {
            display: none;
            margin: 0 0 0 90px;
        }
        .description {
            position: relative;
            .rules {
                position: absolute;
                right: 0;
                top: 0;
                display: inline-block;
                padding: 15px 35px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 700;
                transition: all .25s ease;
                margin-top: 20px;
                background: #002d58;;
                &:hover {
                    background-color: #00a0e3;
                }
            }
        }
        .main-box {
            width: 100%;
            border: 1px solid #00a0e3;
            height: 100%;
            margin-top: 0;
            position: relative;
            padding: 0 40px 20px;
            box-sizing: border-box;
            /* min-height: 1000px */
            .left-menu {
                height: 100%;
                background: #00a0e3;
                width: 330px;
                display: inline-block;
                color: #fff;
                vertical-align: top;
                position: absolute;
                h4 {
                    margin: 25px 0 10px;
                }
                .inner {
                    padding: 30px;
                }
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    width: 100%;
                    height: 40px;
                    border: 3px solid #33b3e9;
                    font-size: 11pt;
                    color: #c1ebff;
                    padding: 0 10px;
                    margin: 10px 0;
                    background: #0789c7 url(img/select-arrow.png) no-repeat right center;
                    &:hover {
                        background-color: #1a97d3;
                    }
                    option {
                        background: #0789c7;
                        color: #c1ebff;
                        margin: 40px;
                    }
                }
                label {
                    display: block;
                    margin-bottom: 8px;
                    input:checked+span.checkbox {
                        background: #c1ebff;
                    }
                    span {
                        &.checkbox {
                            width: 18px;
                            height: 16px;
                            display: inline-block;
                            border: 3px solid #33b3e9;
                            background: #0789c7;
                            vertical-align: middle;
                        }
                        &.label {
                            color: #c1ebff;
                            margin-left: 10px;
                            font-size: 10pt;
                        }
                        &.checkbox:hover {
                            background: #1393d0;
                        }
                    }
                }
                .submit {
                    width: 100px;
                    height: 40px;
                    border: 3px solid #33b3e9;
                    font-size: 11pt;
                    color: #c1ebff;
                    padding: 5px 15px;
                    margin: 10px 0;
                    background: #0789c7;
                    transition: background-color .25s ease;
                    text-transform: uppercase;
                    &:hover {
                        background-color: #1a97d3;
                    }
                }
                .divider {
                    border-bottom: 4px solid #40b8ea;
                    margin: 30px -30px;
                    padding: 0;
                }
                .subcategories a {
                    display: none;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 12px 0;
                    transition: color 0.25s ease;
                    &.all-products {
                        display: block;
                    }
                    &.level1:hover {
                        color: #c1ebff;
                    }
                    &.level2:hover,
                    &.level3:hover {
                        color: #fff;
                    }
                    &.current {
                        font-weight: 700;
                        display: block;
                    }
                    &.parent,
                    &.sibling,
                    &.child {
                        display: block;
                    }
                    &.level2 {
                        background: #0789c7;
                        margin: 0 -30px;
                        padding: 10px 0 10px 50px;
                        font-size: 11pt;
                        color: #c1ebff;
                    }
                    &.level3 {
                        background: #0679b0;
                        margin: 0 -30px;
                        padding: 10px 0 10px 70px;
                        font-size: 10pt;
                        color: #c1ebff;
                    }
                    &.hidden {
                        display: none;
                    }
                }
            }
            .right-content {
                display: inline-block;
                padding: 40px 0;
                vertical-align: top;
                overflow: hidden;
                position: relative;
                h4 {
                    font-weight: 400;
                    font-size: 24pt;
                    display: block;
                    padding-bottom: 30px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #e5e5e5;
                    color: #242d58;
                }
                .category-description {
                    margin-bottom: 30px;
                    border-bottom: 1px solid #e5e5e5;
                    padding-bottom: 20px;
                    color: #242d58;
                    .images {
                        margin-top: 30px;
                        text-align: center;
                        line-height: 0;
                    }
                    .category-image {
                        width: 100%;
                        max-width: 240px;
                        display: inline-block;
                        margin-right: 10px;
                        padding: 0;
                        margin-top: 5px;
                    }
                    .images a:last-of-type .category-image {
                        margin-right: 0;
                    }
                }
                .thumbnail {
                    width: 100%;
                }
            }
        }
    }
}

.recommended .thumbnail {
    width: 100%;
}

.product-box {
    width: 226px;
    overflow: hidden;
    display: inline-block;
    margin: 10px 30px 10px 0;
    vertical-align: top;
    .image-wrapper {
        height: 180px;
        overflow: hidden;
        border: 1px solid #e5e5e5;
        display: block;
        margin: 0;
        text-align: center;
        img {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            transition: opacity 0.25s ease;
            &:hover {
                opacity: 0.7;
            }
            &.noimage {
                width: 150px;
            }
        }
    }
    .description {
        margin-top: 10px;
        h5 {
            font-size: 12pt;
            text-transform: uppercase;
            font-weight: 400;
            color: #242d58;
            margin-bottom: 3px;
        }
        span {
            font-size: 11pt;
            color: #00a0e3;
        }
    }
}

.recommended .product-box {
    width: 215px;
    margin: 10px 17px 10px 0;
    .image-wrapper {
        height: 170px;
    }
}

section.products {
    .pagination {
        /* position: absolute; */
        /* right: 40px; */
        /* bottom: 30px; */
        padding-top: 20px;
        margin-bottom: 30px;
        border-top: 1px solid #e5e5e5;
    }
    .single {
        h1 {
            margin-bottom: 10px;
        }
        h3 {
            color: #242d58;
            margin: 0 0 50px;
            font-size: 14pt;
            text-transform: none;
        }
        .controls {
            float: right;
            margin-top: 20px;
            .back {
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase;
                text-decoration: underline;
                color: #a0a0a0;
                font-size: 10pt;
                margin-right: 30px;
                transition: color 0.25s ease;
                &:hover {
                    color: #a0a0a0;
                }
            }
            .ask {
                display: inline-block;
                vertical-align: middle;
                padding: 15px 30px;
                background: #00a0e3;
                color: #fff;
                font-weight: 700;
                font-size: 10pt;
                text-transform: uppercase;
                transition: opacity 0.25s ease;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        .left {
            display: inline-block;
            margin-right: 40px;
            overflow: hidden;
            .cycle-carousel-wrap {
                white-space: normal !important;
                position: static !important;
            }
            #cycle-2 .cycle-sentinel {
                display: none !important;
            }
            .cycle-carousel-wrap .cycle-slide {
                width: 100px !important;
                height: 100px !important;
                border: 5px solid #fff;
                margin: 0 10px 0 0;
                overflow: hidden;
                position: relative;
                cursor: pointer;
            }
        }
    }
}

#cycle-2 img {
    width: 100px !important;
    height: auto !important;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#cycle-1 .cycle-slide {
    // border: 1px solid #e5e5e5;
}

section.products .single {
    .left {
        .cycle-carousel-wrap .cycle-slide-active {
            border-color: #00a0e3 !important;
        }
        .full-image {
            width: 100%;
            vertical-align: top;
        }
    }
    .right {
        display: inline-block;
        vertical-align: top;
        width: 530px;
        a:hover {
            color: #505050;
        }
        ul {
            margin-bottom: 10px;
            li {
                width: 47%;
                display: inline-block;
                margin-left: 2%;
                &:before {
                    content: "\25CF";
                    color: #00a0e3;
                    margin-right: 10px;
                }
            }
        }
        h4 {
            font-size: 13pt;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 10px;
            &:first-of-type {
                margin-top: 0;
            }
        }
        .divider {
            margin: 40px 0 30px;
            padding: 0;
        }
        .attribute-box {
            display: inline-block;
            width: 45%;
            margin: 20px 0 0 5%;
            vertical-align: top;
            img {
                float: left;
                width: 27px;
                height: 25px;
            }
            li {
                list-style-type: none;
                margin-left: 40px;
                font-size: 11pt;
                color: #002d58;
                &:first-of-type {
                    margin-top: 3px;
                }
            }
        }
    }
    .recommended {
        margin: 50px 0 20px;
        h3 {
            color: #34B3EA;
            font-size: 20pt;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
    }
    #slideshow-2 {
        margin-top: 35px;
    }
}

@media (max-width: 1920px) {
    header div.slider div.slides div.slide img.slide-bg {
        right: 0;
        margin: 0;
        left: auto;
    }
}

@media (min-width: 1241px) {
    .wrapper {
        width: 1181px;
    }
    .mwrapper {
        width: 590.5px !important;
    }
    header div {
        &.top ul.menu {
            margin: 53px 0 0 45px;
        }
        &.slider-small div.slides div.slide img.slide-bg {
            right: -250px;
        }
    }
    section {
        &.career {
            .jobs li:nth-of-type(3n+0) a {
                margin-right: 0 !important;
            }
            ul li {
                width: 46%;
            }
        }
        &.clients .logos a:nth-of-type(5n+0) img,
        &.about .about-box:nth-of-type(3n+0) {
            margin-right: 0;
        }
        &.products {
            .main-box {
                a:nth-of-type(4n+0) .product-box {
                    margin-right: 0;
                }
                .right-content {
                    /* width: 750px */
                }
            }
            .pagination {
                width: 100%;
            }
            .single {
                .left {
                    width: 600px;
                }
                .right {
                    width: 530px;
                }
            }
            .products-triangle {
                display: block;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1240px) {
    .wrapper {
        width: 964px;
    }
    .mwrapper {
        width: 495px !important;
    }
    header div {
        &.top ul.menu {
            margin: 53px 0 0 45px;
        }
        &.slider-small div.slides div.slide img.slide-bg {
            right: -450px;
        }
        &.slider-front div.slides div.slide img.slide-bg {
            right: -150px;
        }
    }
    section {
        &.our-services ul {
            text-align: center;
            li {
                text-align: left;
            }
        }
        &.our-product ul.right {
            text-align: right;
        }
        &.our-company div.right ul {
            padding: 211px 0 0 70px;
        }
        &.eco ul {
            margin-right: -60px;
            li {
                margin-right: 60px;
            }
        }
        &.contacts .contact-box img {
            max-width: 100%;
        }
        &.numbers {
            .size-box:nth-of-type(3n+0),
            .wolumen-box:nth-of-type(2n+0) {
                border-right: 1px solid #e3e3e3;
            }
            .size-box:last-of-type,
            .wolumen-box:last-of-type {
                border-top: none;
            }
        }
        &.text {
            .left-menu {
                width: 250px;
            }
            .right-content {
                width: 660px;
            }
        }
        &.products {
            .product-lists>a {
                width: 145px;
                padding: 0 20px 0 15px;
                >div {
                    font-size: 11pt;
                }
            }
            .main-box {
                width: 958px;
                .left-menu {
                    width: 300px;
                }
                .right-content {
                    width: 540px;
                }
            }
            .pagination {
                width: 550px;
            }
            .single {
                .left {
                    width: 460px;
                }
                .right {
                    width: 400px;
                }
            }
            .products-triangle {
                display: block;
            }
        }
    }
    footer .right {
        height: 814px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .wrapper {
        width: 708px;
    }
    .mwrapper {
        width: 354px !important;
    }
    header div.top ul {
        &.menu {
            li {
                font-size: 9pt;
                &:nth-child(1n+2) {
                    margin-left: 20px;
                }
            }
        }
        &.langswitch {
            position: absolute;
            top: 20px;
            right: -20px;
            -webkit-transform: translateY(0%);
            -ms-transform: translateY(0%);
            transform: translateY(0%);
        }
    }
    .slider-small h3 {
        font-size: 24pt !important;
        line-height: 110% !important;
    }
    header div {
        &.slider-front h3 {
            font-size: 24pt !important;
            line-height: 110% !important;
        }
        &.slider-small div.slides div.slide img.slide-bg {
            right: -500px;
        }
        &.slider-front div.slides div.slide img.slide-bg {
            right: -300px;
        }
    }
    div.slider div.wrapper {
        margin-left: 60px;
    }
    section {
        &.our-services ul {
            text-align: center;
            margin-right: -27px;
            li {
                text-align: left;
                margin: 0 25px 65px 0;
            }
        }
        &.our-product {
            ul.right {
                text-align: right;
            }
            div.product {
                display: none;
            }
        }
        &.our-company div.right ul {
            padding: 211px 25px 0 35px;
        }
        &.eco {
            background: #6DE18E url(img/gfx_bg_eco.gif) center right no-repeat;
            ul {
                margin-right: -30px;
                text-align: center;
                li {
                    margin-right: 60px;
                    text-align: center;
                    width: 100%;
                    &:nth-last-child(1) {
                        margin-top: 60px;
                    }
                }
            }
        }
        &.contacts {
            .contact-box {
                width: 300px;
                img {
                    max-width: 100%;
                }
            }
            .contact-form .contact-field {
                width: 350px;
            }
        }
        &.career .jobs li {
            &:nth-of-type(2n+0) a {
                margin-right: 0 !important;
            }
            a {
                width: 290px;
            }
        }
        &.about .about-box:nth-of-type(2n+0),
        &.clients .logos a:nth-of-type(3n+0) img {
            margin-right: 0;
        }
        &.about .about-box {
            margin-right: 18px;
        }
        &.numbers {
            .size-box:nth-of-type(2n+0),
            .wolumen-box {
                border-right: 1px solid #e3e3e3;
            }
            .size-box:nth-of-type(1),
            .wolumen-box:nth-of-type(1),
            .size-box:nth-of-type(2),
            .wolumen-box:nth-of-type(2) {
                border-bottom: none;
            }
        }
        &.text {
            .left-menu {
                width: 220px;
            }
            .right-content {
                width: 450px;
                margin-left: 20px;
            }
        }
        &.products {
            .product-lists {
                margin-bottom: 40px;
                >a {
                    width: 145px;
                    padding: 0 20px 0 15px;
                    margin-bottom: 15px;
                    >div {
                        font-size: 11pt;
                    }
                }
            }
            .main-box {
                width: 100%;
                .left-menu {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                    .subcategories {
                        display: inline-block;
                        width: 55%;
                        vertical-align: top;
                        margin-right: 4%;
                        a {
                            margin-right: 0 !important;
                        }
                    }
                    .filters {
                        display: inline-block;
                        width: 35%;
                        vertical-align: top;
                        border-left: 4px solid #40b8ea;
                        padding-left: 4%;
                    }
                    .divider {
                        display: none;
                    }
                }
                .right-content {
                    width: 100%;
                    left: 0;
                    h4 {
                        width: 630px;
                    }
                    .product-box {
                        margin-right: 50px;
                    }
                }
            }
            .pagination {
                width: 630px;
            }
            .single {
                h1 {
                    font-size: 36pt;
                    margin-bottom: 0;
                }
                .controls {
                    margin-top: -15px;
                    text-align: right;
                    a {
                        display: block !important;
                        &.back {
                            margin: 0 0 15px;
                        }
                    }
                }
                .left {
                    width: 100%;
                    display: block;
                    margin: 0 0 50px;
                }
                .right {
                    width: 100%;
                    display: block;
                }
            }
            .main-box .right-content {
                width: 89%;
            }
        }
    }
    footer {
        .right {
            height: 814px;
        }
        form input[type="email"] {
            width: 180px;
        }
    }
    section.career .practice {
        .left,
        .right {
            width: calc(100% - 30px);
            float: left;
        }
    }
}

@media (min-width: 480px) and (max-width: 860px) {
    section.news .news-box,
    section.about .about-box,
    section.numbers .size-box,
    section.numbers .wolumen-box {
        width: 340px;
        max-width: 100%;
    }
    section.numbers .size-box:nth-last-of-type(3) {
        display: none;
    }
    h1 {
        font-size: 24pt !important;
        margin: 10px 0 30px !important;
    }
    .wrapper {
        width: 420px;
    }
    .mwrapper {
        width: 420px !important;
    }
    section {
        div>h1,
        h1 {
            font-size: 60px;
            line-height: 63px;
        }
    }
    header div {
        &.top {
            &:after {
                content: '';
                display: block;
                clear: both;
                visibility: hidden;
            }
            div.logo {
                float: none;
                width: 100%;
                text-align: center;
            }
            ul {
                &.menu {
                    text-align: center;
                    margin-left: 0;
                    li {
                        margin-bottom: 15px;
                        &:nth-child(1n+2) {
                            margin-left: 20px;
                        }
                    }
                }
                &.langswitch {
                    position: absolute;
                    top: 20px;
                    right: -20px;
                    -webkit-transform: translateY(0%);
                    -ms-transform: translateY(0%);
                    transform: translateY(0%);
                }
            }
        }
        &.slider-small .wrapper {
            padding-left: 15% !important;
            width: 320px;
        }
    }
    .slider-small h3 {
        font-size: 20pt !important;
        line-height: 110% !important;
    }
    header div {
        &.slider-front {
            h3 {
                font-size: 20pt !important;
                line-height: 110% !important;
            }
            .desc {
                width: 300px !important;
                font-size: 11pt !important;
            }
        }
        &.slider-small div.slides div.slide img.slide-bg {
            right: -570px;
        }
        &.slider-front div.slides div.slide img.slide-bg {
            height: 350px;
            right: -50px;
            top: 220px;
        }
    }
    div.slider div.wrapper {
        margin-left: 60px;
    }
    header div.slider {
        div.slides div.slide {
            h3 {
                font-size: 65px;
                line-height: 51px;
            }
            div.wrapper {
                padding-top: 240px;
                padding-left: 0;
            }
        }
        ul.toggles {
            padding-left: 0;
        }
    }
    section {
        &.our-mission .president-photo {
            float: none;
            margin: 0 0 20px;
            width: 100%;
        }
        &.our-services ul {
            text-align: center;
            margin-right: -27px;
            li {
                text-align: left;
                margin: 0 25px 65px 0;
            }
        }
        &.our-product {
            background: url(img/gfx_bg_op.gif) right bottom no-repeat;
            ul.right {
                margin-top: 50px;
                float: left;
                text-align: left;
            }
            div.product {
                display: none;
            }
        }
        &.our-company {
            background: url(img/gfx_bg_company.jpg) top left no-repeat;
            div {
                &.left {
                    width: 100%;
                }
                &.right {
                    display: none;
                }
            }
        }
        &.eco {
            background: #6DE18E url(img/gfx_bg_eco.gif) center right no-repeat;
            ul {
                margin-right: -30px;
                text-align: center;
                li {
                    margin-right: 60px;
                    text-align: left;
                    &:nth-child(1n+2) {
                        margin-top: 60px;
                    }
                }
            }
        }
        &.contacts .contact-form .contact-field {
            width: 350px;
        }
        &.career .jobs li a {
            &.applynow {}
            &.applynow-rwd {
                display: inline-block;
            }
        }
        &.clients .logos {
            img {
                width: 200px;
            }
            a:nth-of-type(2n+0) img {
                margin-right: 0;
            }
        }
        &.numbers {
            .size-box,
            .wolumen-box {
                border-right: 1px solid #e3e3e3;
                border-bottom: none;
            }
            .size-box:last-of-type,
            .wolumen-box:last-of-type {
                border-bottom: 1px solid #e3e3e3;
            }
        }
        &.history {
            .external-scroll_x {
                &.scroll-scrollx_visible {
                    width: 300px;
                }
                .scroll-bar {
                    width: 100px;
                }
            }
            .calendar {
                height: 350px;
            }
        }
        &.text {
            .left-menu {
                display: block;
                width: 220px;
            }
            .right-content {
                display: block;
                margin: 50px 0 0;
                width: 100%;
            }
        }
        &.products {
            .product-lists {
                margin-bottom: 40px;
                >a {
                    width: 145px;
                    padding: 0 20px 0 15px;
                    margin-bottom: 15px;
                    >div {
                        font-size: 11pt;
                    }
                }
            }
            .main-box {
                width: 100%;
                .left-menu {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                }
                .right-content {
                    width: 100%;
                    left: 0;
                    h4 {
                        width: 340px;
                    }
                    .product-box {
                        margin-right: 50px;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    .recommended a {
        .product-box {
            width: 199px;
        }
        &:nth-of-type(2n+0) .product-box {
            margin-right: 0;
        }
    }
    section.products {
        .pagination {
            width: 340px;
        }
        .description {
            .rules {
                position: static;
                margin-bottom: 20px;
            }
            h1 {
                margin-bottom: 20px;
            }
        }
        .main-box .right-content {
            width: 81%;
        }
        .description .rules {
            display: block;
            text-align: center;
        }
        .single {
            h1 {
                font-size: 36pt;
                margin-bottom: 0;
            }
            .controls {
                margin-top: -30px;
                text-align: left;
                display: inline-block;
                position: static;
                float: none;
                margin-bottom: 20px;
                a {
                    display: block !important;
                    &.back {
                        margin: 0 0 15px;
                    }
                }
            }
            .left {
                width: 100%;
                display: block;
                margin: 0 0 50px;
            }
            .right {
                width: 100%;
                display: block;
            }
        }
    }
    footer {
        .right {
            height: 814px;
            float: none;
            width: 100%;
        }
        form input[type="email"] {
            width: 180px;
        }
    }
    section.career .practice {
        .left,
        .right {
            width: calc(100% - 30px);
            float: left;
        }
    }
}

@media (max-width: 479px) {
    h1 {
        font-size: 24pt !important;
        margin: 10px 0 30px !important;
        &:first-of-type {
            margin-top: 40px !important;
        }
    }
    .wrapper {
        width: calc(100% - 30px);
    }
    .mwrapper {
        width: calc(100% - 30px) !important;
    }
    section {
        padding: 40px 0 !important;
        div>h1,
        h1 {
            font-size: 50px;
            line-height: 53px;
        }
    }
    header div {
        &.top {
            div.logo {
                float: none;
                width: 100%;
                text-align: center;
            }
            ul {
                &.menu {
                    text-align: center;
                    margin-left: 0;
                    li {
                        margin-bottom: 15px;
                        &:nth-child(1n+2) {
                            margin-left: 20px;
                        }
                    }
                }
                &.langswitch {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    -webkit-transform: translateY(0%);
                    -ms-transform: translateY(0%);
                    transform: translateY(0%);
                }
            }
        }
        &.slider-small .wrapper {
            padding-left: 2% !important;
            width: 270px;
        }
    }
    .slider-small h3 {
        font-size: 17pt !important;
        line-height: 110% !important;
    }
    header div.slider-front {
        h3 {
            font-size: 17pt !important;
            line-height: 110% !important;
        }
        .desc {
            width: 50%;
            font-size: 10pt !important;
        }
    }
    div.slider div.wrapper {
        margin-left: 20px;
    }
    header {
        div {
            &.slider {
                div.slides div.slide {
                    h3 {
                        font-size: 65px;
                        line-height: 51px;
                        font-size: 16pt !important;
                        line-height: 150% !important;
                        br {
                            display: none;
                        }
                    }
                    .desc {
                        br {
                            display: none;
                        }
                        width: 100%;
                        margin-top: 20px !important;
                        line-height: 20px !important;
                    }
                    div.wrapper {
                        padding-left: 0;
                    }
                }
                ul.toggles {
                    padding-left: 0;
                }
            }
            &.slider-front div.slides div.slide img.slide-bg {
                height: 300px;
                right: -40px;
                top: 290px;
            }
            &.slider {
                height: 590px;
            }
        }
        .slider-small .slide img {
            display: none;
        }
        div.line {
            top: 190px;
        }
    }
    section.numbers .size-box:nth-last-of-type(3) {
        display: none;
    }
    section {
        &.our-services ul {
            text-align: center;
            width: 100%;
            li {
                text-align: left;
                margin: 0 0 65px;
                widows: 100%;
                width: 100% !important;
                max-width: 340px;
                div.thumb+a {
                    width: 115px !important;
                    text-align: center;
                }
            }
        }
        &.our-product {
            background: url(img/gfx_bg_op.gif) right bottom no-repeat;
            ul.right {
                margin-top: 50px;
                float: left;
                text-align: left;
            }
            div.product {
                display: none;
            }
        }
        &.our-company {
            background: url(img/gfx_bg_company.jpg) top left no-repeat;
            div {
                &.left {
                    width: 100%;
                }
                &.right {
                    display: none;
                }
            }
        }
        &.our-mission {
            .desc {
                font-size: 11pt !important;
                line-height: 150% !important;
            }
            .quota,
            .author {
                font-size: 11pt !important;
            }
            .president-photo {
                float: none;
                margin: 0 0 20px;
                width: 100%;
            }
        }
        &.eco {
            background: #6DE18E url(img/gfx_bg_eco.gif) center right no-repeat;
            ul {
                width: 100%;
                text-align: center;
                li {
                    width: 100%;
                    text-align: left;
                    margin-right: 0 !important;
                    &:nth-child(1n+2) {
                        margin-top: 60px;
                    }
                }
            }
        }
        &.contacts .contact-form .contact-field {
            width: 350px;
        }
        &.career .jobs li a {
            &.applynow {}
            &.applynow-rwd {
                display: inline-block;
            }
        }
        &.clients .logos img {
            width: 130px;
            width: 200px;
            margin: 0 3px 3px 0;
        }
        &.numbers {
            .size-box,
            .wolumen-box {
                border-right: 1px solid #e3e3e3;
                border-bottom: none;
            }
            .size-box:last-of-type {
                border-bottom: 1px solid #e3e3e3;
            }
            .wolumen-box {
                &:last-of-type {
                    border-bottom: 1px solid #e3e3e3;
                }
                width: 100%;
            }
        }
        &.history {
            .external-scroll_x {
                &.scroll-scrollx_visible {
                    width: 250px;
                }
                .scroll-bar {
                    width: 80px;
                }
            }
            .calendar {
                height: 350px;
            }
        }
        &.text {
            .left-menu {
                display: block;
                width: 220px;
            }
            .right-content {
                display: block;
                margin: 50px 0 0;
                width: 100%;
            }
        }
        &.products {
            .product-lists {
                margin-bottom: 40px;
                >a {
                    width: 145px;
                    padding: 0 20px 0 15px;
                    margin-bottom: 15px;
                    >div {
                        font-size: 11pt;
                    }
                }
            }
            .main-box {
                width: 100%;
                .left-menu {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                }
                .right-content {
                    width: 100%;
                    left: 0;
                    padding-bottom: 90px !important;
                    .product-box {
                        margin: 10px 0;
                    }
                    h4 {
                        width: 100%;
                        font-size: 14pt;
                        margin-top: 15px;
                        padding-bottom: 15px;
                    }
                }
            }
            .pagination {
                width: 100%;
                right: 0;
                padding-right: 10px;
                transform: scale(0.85);
            }
            .main-box .right-content {
                width: 100%;
                padding: 0;
                text-align: center;
            }
            .description .rules {
                display: block;
                text-align: center;
                position: static;
                margin-bottom: 20px;
            }
            .single {
                h1 {
                    font-size: 36pt;
                    margin-bottom: 0;
                }
                .controls {
                    margin-top: -30px;
                    text-align: left;
                    display: inline-block;
                    position: static;
                    float: none;
                    margin-bottom: 20px;
                    a {
                        display: block !important;
                        &.back {
                            margin: 0 0 15px;
                        }
                    }
                }
                .left {
                    width: 100%;
                    display: block;
                    margin: 0 0 50px;
                }
                .right {
                    width: 100%;
                    display: block;
                    ul li,
                    .attribute-box {
                        width: 100%;
                    }
                }
            }
            .product-lists {
                >a:last-of-type {
                    margin-right: 20px;
                }
                a {
                    display: block;
                    width: 90%;
                    background-size: cover;
                    height: 80px;
                }
            }
        }
        &.news .news-box,
        &.about .about-box,
        &.numbers .size-box {
            width: 100%;
        }
        &.career .jobs li {
            width: 100%;
            a {
                width: 100%;
            }
        }
        &.contacts {
            .contact-box {
                width: 100% !important;
                .image-wrapper {
                    width: 100% !important;
                }
            }
            .contact-field {
                width: 100% !important;
            }
        }
    }
    footer {
        h1 {
            font-size: 18pt !important;
        }
        .right {
            height: 814px;
            float: none;
            width: 100%;
        }
        form input[type="email"] {
            width: 180px;
        }
        .form_subscribe button {
            width: 220px !important;
            margin-top: 10px;
        }
    }
    section.career .practice {
        .left,
        .right {
            width: calc(100% - 30px);
            float: left;
        }
    }
}

.konsola {
    position: fixed;
    background: yellow;
    bottom: 0;
    right: 0;
    z-index: 999;
    max-height: 300px;
    overflow-y: scroll;
    width: 30%;
    padding: 20px;
}

footer {
    .left.mwrapper {
        padding-bottom: 0;
        padding-top: 30px;
        img {
            margin-bottom: 15px;
        }
    }
    background-image: none;
}

header div.top ul.menu li {
    &.current-menu-item::before,
    &.current-menu-ancestor::before,
    &:hover::before {
        border-color: #002d58;
    }
}

.btn-mda-555 {
    background: #002d58 !important;
    border: none !important;
    transition: background .3s ease !important;
    opacity: 1 !important;
    color: #FFF !important;
    &:hover,
    &.active {
        background: #00a0e3 !important;
        border: none !important;
        opacity: 1 !important;
        color: #FFF !important;
    }
    .subinfo {
        color: #FFF !important;
    }
}