.politech-contact {
    .wrapper {
        &:after {
            content: '';
            clear: both;
            display: block;
            visibility: hidden;
        }
        .row {
            margin-left:-15px;
            margin-right:-15px;
        }
    }
    .contact-box {
        float: left;
        padding-bottom:30px;
        @media (max-width: 480px){
            padding-left:0;
            padding-right:0;
        }
    }
}

@media(max-width:768px){
    section.contacts .contact-form .contact-field,
    section.contacts .contact-form .message-field {
        width:100%;
        input, textarea {
            width:100%;
        }
    }
}