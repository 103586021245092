.politech-page-banner {
    border-bottom: 2px solid #40b8ea;
    &__content {
        height: 220px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        h3 {
            font-size: 30pt;
            font-weight: 800;
            text-transform: uppercase;
            padding-top: 30px;
            @media(min-width: 500px) {
                padding-top: 60px;
            }
        }
        span.gray,
        grey {
            color: #d6d6d6
        }
        span.lightblue,
        blue {
            color: #00a0e3
        }
        span.darkblue {
            color: #242d58
        }
    }
}