div.wpcf7 {
	margin: 0;
	padding: 0;
}

div.wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-mail-sent-ok {
	border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
	border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
	border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
	border: 2px solid #f7e700;
}

.wpcf7-form-control-wrap {
	position: relative;
}

span.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	font-weight: normal;
	display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
	position: absolute;
	top: 20%;
	left: 20%;
	z-index: 100;
	border: 1px solid #ff0000;
	background: #fff;
	padding: .2em .8em;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	background-image: url('../../images/ajax-loader.gif');
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}

div.wpcf7 .wpcf7-recaptcha iframe {
	margin-bottom: 0;
}

div.wpcf7 input[type="file"] {
	cursor: pointer;
}

div.wpcf7 input[type="file"]:disabled {
	cursor: default;
}

.our-mission,
.our-product,
.our-services,
.eco {
    @media (max-width: 860px) {
        h1, h2, h3, h4, h5, h6 {
            text-align: center;
            width: 100%;
            line-height: 1.15;
        }
        ul.left,
        ul.right {
            width: 100% !important;
            li {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.news input[type="search"]{
    border: 2px solid #00a0e3;
    padding: 10px 20px;
    border-right: none;
    max-width: calc(100% - 100px);
    box-sizing: border-box;
    float: left;
    & + button {
        float:left !important;
    }
}

#disqus_thread {width: 100%;}

@media(max-width: 860px) {
    .our-services {
        ul {
            margin-right: 0 !important;
            li {
                margin-right: 0 !important;
            }
        }
    }
}

.news-box {
    h3 {
        min-height: 76px;
    }
    .poli-content {
        min-height: 194px;
        padding-bottom: 20px;
        position: relative;
        .readmore {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.news form.search {
    @media(max-width: 860px) {
        margin-top: 0 !important;
        margin-bottom: 30px !important;
    }
}

@media(min-width: 1241px) {
    section.news .news-box:nth-of-type(3n+2) {
        margin-right: 70px;
        margin-left: 70px;
    }
}

@media (min-width: 1024px) and (max-width: 1240px) {
    section.news .news-box:nth-of-type(3n+1),
    section.news .news-box:nth-of-type(3n+2) {
        margin-lefT: 0;
        margin-righT: 0;
    }
    section.news .news-box:nth-of-type(3n+2) {
        margin-left: 30px;
        margin-right: 30px;
    }
    section.news .news-box {
        width: 290px;
    }
}

@media(max-width: 1024px) {
    section.news .news-box:nth-of-type(2n+1) {
        margin-left: 0;
        margin-right: 15px;
        width: 330px;
    }
    section.news .news-box:nth-of-type(2n+2) {
        margin-left: 15px;
        margin-right: 0;
        width: 330px;
    }
}

@media(max-width: 860px) {
    section.news .news-box:nth-of-type(n) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

@media(max-width: 1240px) {
    section.about .politech-filmy-fix {
        text-align: center;
        .about-box {
            display: block;
            margin: 0 auto 100px;
        }
    }
}

@media(max-width:860px) {
    .logos {
        text-align: center;
    }
}

.politech-menu-rozwin {
    display: none;
}

@media(min-width: 861px) {
    header nav .menu li:hover .sub-menu {
        margin-top: 7px;
        padding: 20px 20px 10px;
        display: block;
        background-color: #242d58 !important;
        margin-left: -20px;
        color: white;
        width: 240px;
        li {
            &:first-child {
                margin: 0 0 5px !important;
            }
            a {
                color: #FFF !important;
                text-shadow: none !important;
                &:hover {
                    color: #00a0e3 !important;
                }
            }
        }
    }
}

@media (max-width: $mobile-menu-bp) {
    .aspexifblikebox {
        display: none;
    }
    header div.top ul.menu {
        max-height: calc(100vh - 120px);
        min-height: 300px;
        z-index: 9998;
        overflow-y: scroll;
        li.menu-item-has-children {
            position: relative;
            width: 100%;
            .politech-menu-rozwin {
                position: absolute;
                user-select: none;
                display: block;
                right: 15px;
                top: -6px;
                line-height: 1;
                font-weight: 700;
                font-size: 24px;
                cursor: pointer;
            }
        }
        li:before {
            display: none !important;
        }
    }
    ul.sub-menu {
        display: none;
        &.show {
            display: block;
            &+.politech-menu-rozwin {
                top: -9px !important;
                right: 18px !important;
            }
        }
    }
}

.single-blog .row .col-right .widgets .blog-widget a.tag {
    color: #fff;
    display: inline-block;
    margin: 0;
    padding: 0 2px 0 1px;
}

.search-submit-blog {
    padding: 14px 26px;
    border: none;
    color: #fff;
    font-size: 10pt;
    text-transform: uppercase;
    font-weight: 700;
    transition: background .25s;
    position: relative;
    float: right;
    transition: all .25s ease;
    outline: none;
    background: #002d58;
    &:hover {
        background: #00a0e3;
    }
}

a.tag span {
    font-size: 14px;
    font-weight: 700;
    padding: 2px;
    background: #00a0e3;
    line-height: 1.5;
}