.single-blog h1 {
    font-size: 84px;
    margin-top: 38px;
    margin-bottom: 56px
}

.single-blog .row {
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid #e3e3e3;
    float: left
}

.single-blog .row .col-left {
    width: 72%;
    display: block;
    float: left;
    @media(max-width: 1240px){
        width:100%;
        .content {
            padding-right: 0 !important;
        }
        img {
            max-width: 100%;
        }
    }
}
@media(max-width: 1240px){
    .single-blog .row {
        display:block;
        margin:0;
    }
}

.single-blog .row .col-left .content {
    padding-right: 40px;
    font-size: 15px;
    line-height: 1.7;
    text-align: justify
}

.single-blog .row .col-left .content h3 {
    margin: 20px 0
}

.single-blog .row .col-left .content .thumbnail {
    margin: 0 0 48px
}

.single-blog .row .col-right {
    width: 28%;
    display: block;
    float: left;
    @media(max-width: 1240px){
        width:100%;
        margin-top: 30px;
    }
}

.single-blog .row .col-right .widgets {
    border: 1px solid #e3e3e3;
    padding: 20px 30px
}

.single-blog .row .col-right .widgets .blog-widget {
    padding-bottom: 40px;
    overflow: hidden
}

.single-blog .row .col-right .widgets .blog-widget h4 {
    text-transform: uppercase;
    color: #00a0e3;
    padding-bottom: 12px;
    font-size: 16px
}

.single-blog .row .col-right .widgets .blog-widget a.category {
    display: block;
    color: #242d58;
    font-size: 14px
}

.single-blog .row .col-right .widgets .blog-widget a.rpost-thumb img {
    width: 100%;
    border: 1px solid #e3e3e3;
    box-sizing: border-box
}

.single-blog .row .col-right .widgets .blog-widget a.rpost-thumb h4 {
    font-size: 15px;
    font-weight: 400;
    color: #242d58;
    margin-bottom: 20px
}

.single-blog .row.social .share {
    width: 42px;
    height: 41px;
    display: block;
    float: left;
    margin-right: 6px
}

.single-blog .row.social .share-fb {
    background: url("img/icons/share-fb.png") -42px 0 no-repeat
}

.single-blog .row.social .share-twitter {
    background: url("img/icons/share-twitter.png") -42px 0 no-repeat
}

.single-blog .row.social .share-linkedin {
    background: url("img/icons/share-linkedin.png") -42px 0 no-repeat
}

.single-blog .row.social .share-plus {
    background: url("img/icons/share-plus.png") -42px 0 no-repeat
}

.single-blog .row.social .share-pin {
    background: url("img/icons/share-pin.png") -42px 0 no-repeat
}

.single-blog .row.social .share-mail {
    background: url("img/icons/share-mail.png") -42px 0 no-repeat
}

.single-blog .row.social .share-fb:hover,
.single-blog .row.social .share-twitter:hover,
.single-blog .row.social .share-linkedin:hover,
.single-blog .row.social .share-plus:hover,
.single-blog .row.social .share-pin:hover,
.single-blog .row.social .share-mail:hover {
    background-position: -42px 0
}

.single-blog .row.products .product-box-container {
    display: flex
}

.single-blog .row.products .product-box-container .product-box {
    margin-left: 15px;
    margin-right: 15px;
    width: auto
}

.single-blog .row.products .product-box-container .product-box .image-wrapper img {
    width: 100%
}

.single-blog .row.products a:first-child .product-box {
    margin-left: 0
}

.single-blog .row.products a:last-child .product-box {
    margin-right: 0
}

.single-blog .row.comments {
    border: none
}

.single-blog .row.social,
.single-blog .row.products,
.single-blog .row.comments,
.single-blog .row.prev-next {
    padding-top: 40px
}

.single-blog .row.social h3,
.single-blog .row.products h3,
.single-blog .row.comments h3,
.single-blog .row.prev-next h3 {
    font-weight: 400
}

.single-blog .row.social .prev-link,
.single-blog .row.social .next-link,
.single-blog .row.products .prev-link,
.single-blog .row.products .next-link,
.single-blog .row.comments .prev-link,
.single-blog .row.comments .next-link,
.single-blog .row.prev-next .prev-link,
.single-blog .row.prev-next .next-link {
    float: left;
    width: 50%;
    min-height: 1px
}

.single-blog .row.social .prev-link a,
.single-blog .row.social .next-link a,
.single-blog .row.products .prev-link a,
.single-blog .row.products .next-link a,
.single-blog .row.comments .prev-link a,
.single-blog .row.comments .next-link a,
.single-blog .row.prev-next .prev-link a,
.single-blog .row.prev-next .next-link a {
    color: #e3e3e3
}

.single-blog .row.social .prev-link a:hover,
.single-blog .row.social .next-link a:hover,
.single-blog .row.products .prev-link a:hover,
.single-blog .row.products .next-link a:hover,
.single-blog .row.comments .prev-link a:hover,
.single-blog .row.comments .next-link a:hover,
.single-blog .row.prev-next .prev-link a:hover,
.single-blog .row.prev-next .next-link a:hover {
    color: #242d58
}

.single-blog .row.social .prev-link,
.single-blog .row.products .prev-link,
.single-blog .row.comments .prev-link,
.single-blog .row.prev-next .prev-link {
    text-align: left;
    background: url("img/icons/arrow-left.png") no-repeat;
    background-position: left
}

.single-blog .row.social .prev-link a,
.single-blog .row.products .prev-link a,
.single-blog .row.comments .prev-link a,
.single-blog .row.prev-next .prev-link a {
    padding-left: 30px
}

.single-blog .row.social .next-link,
.single-blog .row.products .next-link,
.single-blog .row.comments .next-link,
.single-blog .row.prev-next .next-link {
    text-align: right;
    background: url("img/icons/arrow-right.png") no-repeat;
    background-position: right
}

.single-blog .row.social .next-link a,
.single-blog .row.products .next-link a,
.single-blog .row.comments .next-link a,
.single-blog .row.prev-next .next-link a {
    padding-right: 30px
}

.single-blog .row.social h3 {
    padding-bottom: 24px
}

.single-blog .clearfix {
    clear: both
}