header nav .menu li {
    position: relative
}

header.front-page {
    z-index: 10010;
}

@media(min-width: $mobile-menu-bp) {
    header nav .menu li .sub-menu {
        display: none;
        position: absolute
    }
}

header nav .menu li a {
    padding-bottom: 10px
}


section.footer-nav {
    padding: 50px 0;
    background-color: #242d58
}

section.footer-nav ul {
    min-width: 40%;
    max-width: 80%;
    display: inline-block;
    float: left
}

section.footer-nav ul li {
    width: 45%;
    display: inline-block;
    margin-right: 5%;
    margin-bottom: 12px;
    float: left
}

section.footer-nav ul li a {
    font-size: 14px;
    font-family: 'OpenSans', sans-serif;
    color: #acacb8
}

section.footer-nav ul li a:hover {
    text-decoration: underline
}

section.footer-nav .ftcol {
    display: inline-block;
    float: left;
    width: 30%
}

section.footer-nav .ftcol p,
section.footer-nav .ftcol a,
section.footer-nav .ftcol a:hover {
    font-size: 14px;
    color: #acacb8;
    margin-top: 0;
    margin-bottom: 10px
}

section.footer-nav .ftcol .social {
    min-width: 100%
}

section.footer-nav .ftcol .social li {
    margin-right: 4px;
    display: inline-block;
    width: auto
}

section div>h1,
section h1,
section>div>h5 {
    font-size: 42px !important;
    margin-bottom: 48px
}

section.our-mission {
    padding-top: 50px
}

.clearfix:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both
}

.cert-container {
    min-height: 360px;
    display: inline-block;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 30px;
    border: 1px solid #d0d0d0;
    position: relative
}

.cert-container:nth-child(4n+4) {
    margin-right: 0
}

.cert-container img {
    max-width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%)
}

.cert-container:hover {
    border-color: #00a0e3;
}
