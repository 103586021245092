html {
    overflow-x:hidden;
}
.ptch-filters-boxes-first-level:after { clear: both; content: ''; display: block; }
.ptch-filters-boxes-first-level div { width: 30%; }
.ptch-filters-boxes-first-level div:not(:last-child) { margin-right: 5%; }
.ptch-filters-boxes-second-level:after { clear: both; content: ''; display: block; }
.ptch-filters-boxes-second-level div { width: 47.5%; }
.ptch-filters-boxes-second-level div:not(:last-child) { margin-right: 2.5%; }
.ptch-filters-boxes-second-level div:last-child { margin-left: 2.5%; }
.ptch-filters-boxes:after { clear: both; content: ''; display: block; }

.ptch-filters-box { float: left; }
.ptch-filters-box label { display: block; padding: 50px 15px; text-align: center; background: #002d58; color: #FFF; -webkit-transition: opacity .3s ease; transition: opacity .3s ease; }
.ptch-filters-box label:hover { cursor: pointer; opacity: .6; }
.ptch-filters-box label.active { background: #00a0e3; }
.ptch-filters-box input[type="checkbox"]:checked + label { background: #00a0e3; }

.ptch-filters-subcategories { margin-top: 10px; width: 100%; background: #00a0e3; }
.ptch-filters-subcategories:after { clear: both; content: ''; display: block; }
.ptch-filters-subcategories ul { padding: 15px; }
.ptch-filters-subcategories ul li { display: inline-block; }
.ptch-filters-subcategories ul li:not(:last-child) { margin-right: 5px; }
.ptch-filters-subcategories label { display: block; padding: 5px 10px; text-align: center; background: #00a0e3; color: #FFF; -webkit-transition: opacity .3s ease; transition: opacity .3s ease; }
.ptch-filters-subcategories label:hover { cursor: pointer; background: rgba(0, 0, 0, 0.1); }
.ptch-filters-subcategories label.active { background: rgba(0, 0, 0, 0.2); }
.ptch-filters-subcategories input[type="checkbox"]:checked + label { background: rgba(0, 0, 0, 0.2); }

label .ptch-remove-filter { display: none; }
label.active .ptch-remove-filter { -webkit-transform: rotate(45deg); transform: rotate(45deg); margin-left: 8px; display: inline-block; -webkit-transition: all .3s ease; transition: all .3s ease; }
label.active .ptch-remove-filter:hover { -webkit-transform: rotate(-45deg); transform: rotate(-45deg); }

.ptch-hidden { display: none; }

.ptch-filters-attributes .ptch-filters-attributes-select { background: #00a0e3; position: relative; color: #FFF; padding: 20px 0; }
.ptch-filters-attributes .ptch-filters-attributes-select button[type="submit"] { position: absolute; bottom: 30px; right: 15px; width: 100px; height: 40px; border: none; font-weight: bold; font-size: 14px; color: #FFF; padding: 5px 15px; margin: 0; background: #002d58; -webkit-transition: opacity .25s ease; transition: opacity .25s ease; text-transform: uppercase; }
.ptch-filters-attributes .ptch-filters-attributes-select button[type="submit"]:hover { opacity: .6; }
.ptch-filters-attributes .ptch-filters-attributes-select:before { content: ''; width: calc(100% - 30px); left: 15px; height: 1px; background: rgba(193, 235, 255, 0.42); position: absolute; top: 0; }
.ptch-filters-attributes .ptch-filters-attributes-select div div { display: block; padding: 0 45px 30px 15px; float: left; font-weight: bold; }
.ptch-filters-attributes .ptch-filters-attributes-select div div ul { font-weight: 400; padding-top: 5px; padding-bottom: 15px; font-size: 14px; }
.ptch-filters-attributes .ptch-filters-attributes-select:after { clear: both; content: ''; display: block; }
.ptch-filters-attributes .ptch-filters-attributes-selected { margin-top: 20px; line-height: 40px; font-size: 12px; }
.ptch-filters-attributes .ptch-filters-attributes-selected .selected-atr { display: inline-block; padding: 4px 6px; background: #0789c7; color: #FFF; font-size: 12px; line-height: 1; padding: 8px; margin-left: 6px; }
.ptch-filters-attributes .ptch-filters-attributes-selected button { height: 40px; float: right; border: none; font-weight: bold; font-size: 14px; color: #FFF; padding: 5px 15px; margin: 0; background: #002d58; -webkit-transition: opacity .25s ease; transition: opacity .25s ease; text-transform: uppercase; }
.ptch-filters-attributes .ptch-filters-attributes-selected button:hover { opacity: .6; }
.ptch-filters-attributes label { display: block; color: #FFF; -webkit-transition: opacity .3s ease; transition: opacity .3s ease; margin-top: 10px; }
.ptch-filters-attributes label:hover { cursor: pointer; }
.ptch-filters-attributes label:hover .checkbox { background: #1393d0; }
.ptch-filters-attributes label.active { background: rgba(0, 0, 0, 0.2); }
.ptch-filters-attributes label.active .checkbox { background: #c1ebff; }
.ptch-filters-attributes label .checkbox { margin-right: 6px; width: 18px; height: 16px; position: relative; margin-top: -3px; display: inline-block; border: 3px solid #33b3e9; background: #0789c7; vertical-align: middle; }
.ptch-filters-attributes input[type="checkbox"]:checked + label .checkbox { background: #c1ebff; }

.ptch-searchbar { padding: 50px 0 40px; }
.ptch-searchbar:after { clear: both; content: ''; display: block; }
.ptch-searchbar #products { float: left; margin: 0; }
@media (max-width: 860px) { .ptch-searchbar #products { float: none; } }
.ptch-searchbar #ptch-products-search { float: right; }
@media (max-width: 860px) { .ptch-searchbar #ptch-products-search { float: none; } }
.ptch-searchbar #ptch-products-search:after { clear: both; content: ''; display: block; }
.ptch-searchbar #ptch-products-search input { border: 2px solid #00a0e3; padding: 10px 20px; border-right: none; float: left; max-width: 100%; }
.ptch-searchbar #ptch-products-search button { 
    transition: all .25s ease; outline:none;
    background: #002d58;border: none; color: #FFF; font-weight: bold; float: left; padding: 12px 28px; text-transform: uppercase; }
.ptch-searchbar #ptch-products-search button:hover { 
  
    background-color: #00a0e3;}
@media (max-width: 860px) { 
    .ptch-searchbar #ptch-products-search button { padding: 12px; } 
    .ptch-searchbar #ptch-products-search input { width: calc(100% - 100px);} 
    .ptch-searchbar #ptch-products-search {
        margin-top:30px;
    }
    .ptch-searchbar #ptch-products-search input + button {
        width: 100px;
    }
}

.-js-filter-button { opacity: 0; visibility: hidden; -webkit-transition: all .5s ease; transition: all .5s ease; }
.-js-filter-button.visible { opacity: 1; visibility: visible; }

/* mda-Tooltip container */
.mda-tooltip { position: relative; display: inline-block; }

/* mda-Tooltip text */
.mda-tooltip .mda-tooltiptext { visibility: hidden; background-color: #242d58; color: #fff; text-align: center; padding: 5px 10px; border-radius: 3px; /* Position the mda-tooltip text */ position: absolute; z-index: 1; top: -40px; left: 0; /* Fade in mda-tooltip */ opacity: 0; -webkit-transition: opacity 1s; transition: opacity 1s; }

/* mda-Tooltip arrow */
.mda-tooltip .mda-tooltiptext::after { content: ""; position: absolute; top: 100%; left: 15px; margin-left: -5px; border-width: 5px; border-style: solid; border-color: #242d58 transparent transparent transparent; }

/* Show the mda-tooltip text when you mouse over the mda-tooltip container */
.mda-tooltip:hover .mda-tooltiptext { visibility: visible; opacity: 1; }

.-js-filters-subc {
  text-align:center;
}

section.products .main-box .right-content {
  width: 100% !important;
}
@media (max-width: 1240px){
  section.products .main-box .right-content {
    text-align: center;
  }
  section.products .main-box .right-content .product-box {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 860px){
  .ptch-filters-box, .ptch-filters-boxes-second-level div, .ptch-filters-boxes-first-level div {
    width:100%;
    margin:5px 0 !important;
  }
  .ptch-filters-subcategories ul li { width:100%}
  
}